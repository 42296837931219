<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import FeaturedSelectionStoreModule from "./store/featuredSelectionStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {required} from "@core/utils/validations/validations";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    ItemList, ItemForm,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "featured_selection",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const GN_FEATURED_SELECTION_STORE = "gn-featured-selection-store";
    const featuredSelection = ref({
      name: '',
      title: '',
      items: []
    });


    const item = ref({
      href: "",
      src: "",
      alt: "",
      primary_src: ""
    });

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      setIndex,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      enableAddMode,
      resetForm,
      showList,
    } = UseIndex();

    const rBuild = RequestBuilder();

    // Register modules
    if (!store.hasModule(GN_FEATURED_SELECTION_STORE))
      store.registerModule(GN_FEATURED_SELECTION_STORE, FeaturedSelectionStoreModule);

    onMounted(() => {
      resetFeaturedSelection();
      const {itemsSelected} = props.options;
      if (itemsSelected) featuredSelection.value = itemsSelected;
      updatePreview();
    });


    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateFeaturedSelection();
      } else {
        createFeaturedSelection();
      }
    };

    const resetItem = () => {
      item.value = {
        alt: "",
        href: "",
        src: "",
        primary_src: ""
      };
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      // updatePreview();
    };

    const loadItemFromCache = (val) => {
      featuredSelection.value.items[val.index] = val.item
    }

    const onDeleteItem = (index) => {
      featuredSelection.value.items.splice(index, 1)
      updatePreview();
    };


    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.featuredSelection"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createFeaturedSelection = async () => {
      loading.value = true;
      featuredSelection.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      featuredSelection.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            `${GN_FEATURED_SELECTION_STORE}/addFeaturedSelectionItem`,
            rBuild.clean(featuredSelection.value)
        );

        showSuccessToast(toast, 'Featured Selection',i18n.t("message.featuredSelectionCreated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("message.errorFeaturedSelection"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateFeaturedSelection = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(`${GN_FEATURED_SELECTION_STORE}/updateFeaturedSelectionItem`, {
          id: featuredSelection.value._id,
          heroSwiperData: rBuild.clean(featuredSelection.value),
        });

        showSuccessToast(toast, 'Featured Selection', i18n.t("message.featuredSelectionCreated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
             i18n.t("message.errorFeaturedSelection"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetFeaturedSelection = () => {
      featuredSelection.value = {
        name: '',
        title: '',
        items: [],
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.Featured_selection");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };


    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          featuredSelection: value || featuredSelection.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const saveItem = () => {
      featuredSelection.value.items.push(item.value);
      updatePreview();
      showList();
      resetAll();
    };

    const updateItem = () => {
      featuredSelection.value.items[settings.value.index] = item.value
      updatePreview();
      showList();
      resetAll();
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      featuredSelection,
      loading,
      onAddItem,
      onEditItem,
      onDeleteItem,
      resetAll,
      saveItem,
      updateItem,
      updatePreview,
      settings,
      item
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="featuredSelection"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab :title="$t('content')" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="featuredSelection.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.title") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="featuredSelection.title"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

          </b-list-group-item>

        </b-tab>

        <b-tab :title="$t('settings')">
          <item-list
              :items="featuredSelection.items"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              :max-count="4"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              :size-validation="true"
              :size="'463x202'"
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>
