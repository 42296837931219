import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import {computed, ref, watch} from "@vue/composition-api";
import {useToast} from "vue-toastification/composition";
import {
    showErrorToast,
    showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import mobileBarStore from "./store/mobileBarModule"

export default function useMobileBarList() {
    const WHITELABEL_STORE = "whitelabel-templates";
    const MOBILE_BAR_STORE = "gn-mobile-bar";

    // Register module
    if (!store.hasModule(WHITELABEL_STORE))
        store.registerModule(WHITELABEL_STORE, whitelabelTemplatesModule);
    if (!store.hasModule(MOBILE_BAR_STORE))
        store.registerModule(MOBILE_BAR_STORE, mobileBarStore);

    const toast = useToast();
    const refMobileBarListTable = ref(null);
    const loading = ref(false);
    const perPage = ref(10);
    const totalMobileBars = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("_id");
    const isSortDirDesc = ref(false);
    const recordDeleted = ref(false);

    const editTemplate = computed(
        () => store.state[WHITELABEL_STORE].editTemplate
    );

    const dataMeta = computed(() => {
        const localItemsCount = refMobileBarListTable.value?.localItems.length || 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalMobileBars.value,
        };
    });

    const refetchData = () => {
        refMobileBarListTable.value?.refresh();
    };

    const isSelected = (id) => editTemplate.value.templateData?.mobile_bar._id === id;

    const findSelected = (id) => {
        return (
            refMobileBarListTable.value?.localItems.find((item) => item._id === id) ||
            null
        );
    };

    const selectMobileBar = async (id) => {
        const selected = findSelected(id);

        if (!selected) return;

        const updatedTemplate = {
            ...editTemplate.value,
            templateData: {
                ...editTemplate.value.templateData,
                mobile_bar: selected
            }
        };
        store.commit(`${WHITELABEL_STORE}/SET_EDIT_TEMPLATE`, updatedTemplate);

        try {
            await store.dispatch(`${WHITELABEL_STORE}/updateTemplate`, {
                template: updatedTemplate,
                toast,
                path: 'templateData.mobile_bar'
            });
        } catch (error) {
            showErrorToast(toast, "Error updating template", axiosErrorHandle(error));
        }
    };

    const fetchMobileBars = (ctx, callback) => {
        loading.value = true;

        store
            .dispatch(`${MOBILE_BAR_STORE}/fetchMobileBars`, {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                whitelabelId: store.state.whitelabelCurrencyNabvar.whitelabel._id,
                templateId: editTemplate.value._id,
            })
            .then((r) => {
                loading.value = false;
                const {response, total} = r.data;
                callback(response);
                totalMobileBars.value = total;
            })
            .catch((error) => {
                loading.value = false;
                showErrorToast(
                    toast,
                    "Error fetching mobile bar list",
                    axiosErrorHandle(error)
                );
            });
    };

    watch([currentPage, perPage, searchQuery, recordDeleted], () => {
        refetchData();
    });

    return {
        isSelected,
        fetchMobileBars,
        perPage,
        currentPage,
        totalMobileBars,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refMobileBarListTable,
        refetchData,
        recordDeleted,
        loading,
        selectMobileBar,
    };
}