<script>
import {BButton, BFormInput} from "bootstrap-vue";
import store from "@/store";
import {onMounted, ref} from "@vue/composition-api";
import authStoreModule
  from "@/views/components/whitelabel-templates/Ganamos/config/PageEdit/AuthManagement/store/authStoreModule";

export default {
  name: "LoginView",
  components: {BButton, BFormInput},
  setup(props, {emit}) {
    const AUTH_STORE = "auth-store";
    // Register module
    if (!store.hasModule(AUTH_STORE))
      store.registerModule(
          AUTH_STORE,
          authStoreModule
      );

    const isAuthLoginModalModify = ref(false);
    const isAuthRegisterModalModify = ref(false);

    const setAuthLoginModal = (flag) => {
      isAuthRegisterModalModify.value = flag;
      isAuthLoginModalModify.value = true;
      store.commit(`${AUTH_STORE}/SHOW_LOGIN_MODAL`);
    };

    const prepareUpdateOrCreate = (item = null, action = "edit") => {
      emit("updateOrCreate", {item, action});
    };

    onMounted(() => {
      setAuthLoginModal(true);
    });

    return {
      prepareUpdateOrCreate,
    };
  },
};
</script>

<template>
  <b-button size="md" @click="prepareUpdateOrCreate(null, 'edit')" class="w-100">
    Login Edit
  </b-button>
</template>

<style scoped lang="scss"></style>
