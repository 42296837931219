<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import i18n from "@/libs/i18n";
import ngStoreModule from "@/views/components/whitelabel-templates/Ganamos/store/gnStoreModule";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {showErrorToast, showSuccessToast,} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import mobileBarStoreModule
  from "@/views/components/whitelabel-templates/Ganamos/config/PageEdit/MobileBarManagement/store/mobileBarModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    ItemForm,
    ItemList,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "mobileBar",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const WHITELABEL_APP_STORE = "app-whitelabel-templates";
    const MOBILE_BAR_STORE = "ng-mobile-bar-store";
    const TEMPLATE_STORE = "ng-store";

    if (!store.hasModule(WHITELABEL_APP_STORE))
      store.registerModule(WHITELABEL_APP_STORE, whitelabelTemplatesModule);
    if (!store.hasModule(MOBILE_BAR_STORE))
      store.registerModule(MOBILE_BAR_STORE, mobileBarStoreModule);
    if (!store.hasModule(TEMPLATE_STORE))
      store.registerModule(TEMPLATE_STORE, ngStoreModule);

    const mobileBar = ref({
      name: '',
      gradient_background: '',
      active_link_src: '',
      special_link: {
        text: "",
        src: ""
      },
      links: []
    });

    const item = ref({
      href: "",
      title: "",
      buttonType: "link",
      children: [],
      svgData: "",
      position: 0
    });

    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      toast,
      loading,
      showList,
      enableAddMode,
      setIndex
    } = UseIndex();


    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetMobileBar();
      const {itemsSelected} = props.options;
      if (itemsSelected) mobileBar.value = itemsSelected;
      updatePreview();
    });

    const resetMobileBar = () => {
      mobileBar.value = {
        name: '',
        gradient_background: '',
        active_link_src: '',
        special_link: {
          text: "",
          src: ""
        },
        links: []
      };
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          mobile_bar: value || mobileBar.value,
        }
      };
      store.commit(`${WHITELABEL_APP_STORE}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch(
            `${WHITELABEL_APP_STORE}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.mobile_bar'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateMobileBar();
      } else {
        createMobileBar();
      }
    };

    const updateMobileBar = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${MOBILE_BAR_STORE}/updateMobileBar`,
            {
              id: mobileBar.value._id,
              mobileBarData: rBuilder.clean(mobileBar.value),
            }
        );

        showSuccessToast(toast, "mobile_bar", i18n.t("mobile_bar_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_mobile_bar"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createMobileBar = async () => {
      loading.value = true;
      mobileBar.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      mobileBar.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            `${MOBILE_BAR_STORE}/addMobileBar`,
            rBuilder.clean(mobileBar.value)
        );

        showSuccessToast(toast, "Mobile Bar", i18n.t("mobile_bar_created"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_mobile_bar"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit(`${WHITELABEL_APP_STORE}/RESET_EDIT_TEMPLATE`, "template.mobile_bar");
      store.commit(`${WHITELABEL_APP_STORE}/RESET_EDIT_TEMPLATE/RE_RENDER_TEMPLATE`);
      resetAll();
      emit("reset");
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );


    const resetItem = () => {
      item.value = {
        href: "",
        title: "",
        buttonType: "link",
        children: [],
        svgData: "",
        color: "",
        position: 0
      };
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const onDeleteItem = (index) => {
      mobileBar.value.links.splice(index, 1)
      updatePreview();
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      store.commit(`${TEMPLATE_STORE}/SET_CLICKED_MOBILE_BAR_LINK`, null);
      // updatePreview();
    };

    const loadItemFromCache = (val) => {
      mobileBar.value.links[val.index] = val.item
    }


    const saveItem = () => {
      item.value = {
        _id: uuidGen.generate(),
        ...item.value,
      };
      mobileBar.value.links.push(item.value);
      updatePreview();
      showList();
      resetAll();
      sortMobileBarItems();
    };

    const sortMobileBarItems = () => {
      const links = mobileBar.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      mobileBar.value.links = links;
    };

    const updateItem = () => {
      mobileBar.value.links = mobileBar.value.links.map((i) => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
      sortMobileBarItems();
    };

    const editLinkHandler = (val) => {
      const exist =
          mobileBar.value.links.find((item) => item._id === val._id) !== undefined;
      if (exist) {
        item.value = val;
        enableEditMode();
        showForm();
      } else {
        resetItem();
        showList();
      }
      updatePreview();
    };


    const onClearImage = (key) => {
      updateDynamicValue('', key)
      updatePreview();
    }


    const onUploadedImage = (data, path) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        updateDynamicValue(image, path)
        updatePreview();
      }
    };

    const updateDynamicValue = (value, path) => {
      const properties = path.split('.');
      const last = properties.pop();
      const target = properties.reduce((acc, prop) => acc[prop], mobileBar.value);
      if (target) {
        target[last] = value;
      }
    }

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };


    return {
      isEditMode,
      onSubmit,
      resetData,
      settings,
      onEditItem,
      onAddItem,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onDeleteItem,
      loading,
      resetAll,
      mobileBar,
      saveItem,
      updateItem,
      item,
      editLinkHandler,
      updatePreview,
      onClearImage,
      onUploadedImage,
      errorInLoadImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="mobileBar"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab :title="$t('content')" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                      id="s-name"
                      type="text"
                      v-model="mobileBar.name"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="gradient_background"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="h-name">
                  <template #label>{{ $t("labels.gradientColor") }}</template>
                  <b-input
                      type="text"
                      id="color"
                      v-model="mobileBar.gradient_background"
                      @input="updatePreview()"
                      label="color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="active_link_src"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="activeLink" label-for="m-active-link">
                  <template #label>{{ $t("labels.active_link_image") }}</template>
                  <image-field-form
                      :single-button="true"
                      size-validation="129x55"
                      class="w-100"
                      text="Logo"
                      :value="mobileBar.active_link_src"
                      path="mobileBar"
                      @uploadedImage="onUploadedImage($event, 'active_link_src')"
                      @error="errorInLoadImage"
                      preview="true">
                  </image-field-form>

                  <div class="row" v-if="mobileBar.active_link_src.length > 0">
                    <div class="col">
                      <img
                          style="max-width: 100%"
                          :src="mobileBar.active_link_src"
                          :alt="$t('labels.uploaded-image')"
                          class="mt-1 rounded"
                      />

                      <b-button class="close-btn" @click="onClearImage('active_link_src')" variant="outline-primary"
                                size="sm">
                        X
                      </b-button>
                    </div>
                  </div>
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-list-group-item style="background: none" class="px-0">
            <h6 v-b-toggle.accordion-3>{{ $t("labels.special_link") }}</h6>
            <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
            >
              <b-row>
                <validation-provider
                    class="w-100"
                    #default="validationContext"
                    name="text">
                  <b-col cols="12">
                    <b-form-group label="text" label-for="m-text">
                      <template #label>{{ $t("labels.text") }}</template>
                      <b-form-input
                          id="m-text"
                          type="text"
                          v-model="mobileBar.special_link.text"
                      />
                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </b-form-group>
                  </b-col>
                </validation-provider>
              </b-row>

              <b-row>
                <validation-provider
                    class="w-100"
                    #default="validationContext"
                    name="special_link_src"
                >
                  <b-col cols="12">
                    <b-form-group label="activeLink" label-for="m-active-link">
                      <template #label>{{ $t("labels.image") }}</template>
                      <image-field-form
                          :single-button="true"
                          class="w-100"
                          text="Logo"
                          size-validation="307x306"
                          :value="mobileBar.special_link.src"
                          path="mobileBar"
                          @uploadedImage="onUploadedImage($event, 'special_link.src')"
                          @error="errorInLoadImage"
                          preview="true">
                      </image-field-form>

                      <div class="row" v-if="mobileBar.special_link.src.length > 0">
                        <div class="col">
                          <img
                              style="max-width: 100%"
                              :src="mobileBar.special_link.src"
                              alt="Uploaded Image"
                              class="mt-1 rounded"
                          />

                          <b-button class="close-btn" @click="onClearImage('special_link.src')"
                                    variant="outline-primary" size="sm">
                            X
                          </b-button>
                        </div>
                      </div>
                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </b-form-group>
                  </b-col>
                </validation-provider>
              </b-row>
            </b-collapse>
          </b-list-group-item>
        </b-tab>

        <b-tab :title="$t('settings')">
          <item-list
              :items="mobileBar.links"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
              :max-count="4"
          >
          </item-list>
          <item-form
              :icon-list="['Trophy', 'Timer', 'DarkDice', 'DarkFrontCards']"
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'">
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>
