import { render, staticRenderFns } from "./menuList.vue?vue&type=template&id=649d70fe&scoped=true"
import script from "./menuList.vue?vue&type=script&lang=js"
export * from "./menuList.vue?vue&type=script&lang=js"
import style0 from "./menuList.vue?vue&type=style&index=0&id=649d70fe&prod&lang=scss&scoped=true"
import style1 from "./menuList.vue?vue&type=style&index=1&id=649d70fe&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649d70fe",
  null
  
)

export default component.exports