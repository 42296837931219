<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import vSelect from "vue-select";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import i18n from "@/libs/i18n";
import ngStoreModule from "@/views/components/whitelabel-templates/Ganamos/store/gnStoreModule";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import menuStoreModule
  from "@/views/components/whitelabel-templates/Ganamos/config/PageEdit/MenuManagement/store/menuStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    ItemForm,
    ItemList,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "menu",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const WHITELABEL_APP_STORE = "app-whitelabel-templates";
    const MENU_STORE = "ng-menu-store";
    const TEMPLATE_STORE = "ng-store";

    if (!store.hasModule(WHITELABEL_APP_STORE))
      store.registerModule(WHITELABEL_APP_STORE, whitelabelTemplatesModule);
    if (!store.hasModule(MENU_STORE))
      store.registerModule(MENU_STORE, menuStoreModule);
    if (!store.hasModule(TEMPLATE_STORE))
      store.registerModule(TEMPLATE_STORE, ngStoreModule);

    const menu = ref({
      color: '',
      name: '',
      background_color: '',
      hover_color: '',
      font_size: '',
      background_image: '',
      links: []
    });

    const item = ref({
      href: "",
      title: "",
      buttonType: "link",
      children: [],
      svgData: "",
      color: "",
      position: 0
    });

    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      toast,
      loading,
      showList,
      enableAddMode,
      setIndex
    } = UseIndex();


    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetMenu();
      const {itemsSelected} = props.options;
      if (itemsSelected) menu.value = itemsSelected;
      updatePreview();
    });

    const resetMenu = () => {
      menu.value = {
        color: '',
        name: '',
        background_color: '',
        hover_color: '',
        font_size: '',
        background_image: '',
        links: []
      };
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          menu: value || menu.value,
        }
      };
      store.commit(`${WHITELABEL_APP_STORE}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch(
            `${WHITELABEL_APP_STORE}/updateTemplate`,
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.menu'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateMenu();
      } else {
        createMenu();
      }
    };

    const updateMenu = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            `${MENU_STORE}/updateMenu`,
            {
              id: menu.value._id,
              menuData: rBuilder.clean(menu.value),
            }
        );

        showSuccessToast(toast, "Menu", i18n.t("menu_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createMenu = async () => {
      loading.value = true;
      menu.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      menu.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            `${MENU_STORE}/addMenu`,
            rBuilder.clean(menu.value)
        );

        showSuccessToast(toast, "Menu", i18n.t("menu_created"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit(`${WHITELABEL_APP_STORE}/RESET_EDIT_TEMPLATE`, "template.menu");
      store.commit(`${WHITELABEL_APP_STORE}/RESET_EDIT_TEMPLATE/RE_RENDER_TEMPLATE`);
      resetAll();
      emit("reset");
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );


    const resetItem = () => {
      item.value = {
        href: "",
        title: "",
        buttonType: "link",
        children: [],
        svgData: "",
        color: "",
        position: 0
      };
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const onDeleteItem = (index) => {
      menu.value.links.splice(index, 1)
      updatePreview();
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      store.commit(`${TEMPLATE_STORE}/SET_CLICKED_MENU_LINK`, null);
      // updatePreview();
    };

    const loadItemFromCache = (val) => {
      menu.value.links[val.index] = val.item
    }


    const saveItem = () => {
      item.value = {
        _id: uuidGen.generate(),
        ...item.value,
      };
      menu.value.links.push(item.value);
      updatePreview();
      showList();
      resetAll();
      sortMenuItems();
    };

    const sortMenuItems = () => {
      const links = menu.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      menu.value.links = links;
    };

    const updateItem = () => {
      menu.value.links = menu.value.links.map((i) => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
      sortMenuItems();
    };

    const editLinkHandler = (val) => {
      const exist =
          menu.value.links.find((item) => item._id === val._id) !== undefined;
      if (exist) {
        item.value = val;
        enableEditMode();
        showForm();
      } else {
        resetItem();
        showList();
      }
      updatePreview();
    };

    const onUploadedImage = (data, value) => {

      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        menu.value.background_image = image || "";
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onClearImage = (key) => {
      menu.value[key] = ''
      updatePreview();
    }


    return {
      isEditMode,
      onSubmit,
      resetData,
      settings,
      onEditItem,
      onAddItem,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onDeleteItem,
      loading,
      resetAll,
      menu,
      saveItem,
      updateItem,
      item,
      editLinkHandler,
      updatePreview,
      onUploadedImage,
      errorInLoadImage,
      onClearImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="menu"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab :title="$t('content')" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                      id="s-name"
                      type="text"
                      v-model="menu.name"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="h-name">
                  <template #label>{{ $t("labels.color") }}</template>
                  <b-input
                      type="color"
                      id="color"
                      v-model="menu.color"
                      @input="updatePreview()"
                      label="color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="h-name">
                  <template #label>{{ $t("labels.background-color") }}</template>
                  <b-input
                      type="color"
                      id="background_color"
                      v-model="menu.background_color"
                      @input="updatePreview()"
                      label="background_color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="hover_color" label-for="hover_color">
                  <template #label>{{ $t("labels.hovercolor") }}</template>
                  <b-input
                      type="color"
                      id="background_color"
                      v-model="menu.hover_color"
                      @input="updatePreview()"
                      label="hover_color"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <span>{{ $t('labels.backgroundimage') }}</span> <br/>
              <span>1280x300</span>
              <image-field-form
                  :single-button="true"
                  :text="$t('labels.backgroundimage')"
                  class="w-100 my-1"
                  :value="menu.background_image"
                  size-validation="1280x300"
                  path="menus"
                  @uploadedImage="onUploadedImage($event, 'background_image')"
                  @error="errorInLoadImage"
                  preview="true"
              >
              </image-field-form>

              <div v-if="menu.background_image" class="w-100">
                <img
                    style="max-width: 100%"
                    :src="menu.background_image"
                    alt="Uploaded Image"
                    class="mt-1 rounded"
                />
                <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary"
                          size="sm">
                  X
                </b-button>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <validation-provider
                #default="validationContext"
                name="fontSize"
                class="w-100"
            >
              <b-col cols="12">
                <b-form-group label="With" label-for="fontSize">
                  <template #label>{{ $t("font_size") }}</template>
                  <b-form-input
                      id="fontSize"
                      type="number"
                      @input="updatePreview()"
                      v-model="menu.font_size"
                      :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

        </b-tab>

        <b-tab :title="('settings')">
          <item-list
              :items="menu.links"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>
