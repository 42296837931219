import axios from '@axios'

const APP_URL = process.env.VUE_APP_URL

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchMobileBars(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${APP_URL}/mobileBar`, {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addMobileBar(ctx, mobileBarData) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${APP_URL}/mobileBar`, mobileBarData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        updateMobileBar(ctx, {id, mobileBarData}) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`${APP_URL}/mobileBar/${id}`, mobileBarData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeMobileBar(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${APP_URL}/mobileBar/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
