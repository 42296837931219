<script>

import TemplateSizeSelector from "@/views/components/whitelabel-templates/common/TemplateSizeSelector.vue";
import {BButton, BCard, BCol, BListGroup, BListGroupItem, BPopover, BRow} from "bootstrap-vue";
import {mapState} from "vuex";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import GanamosCharger from "@/views/components/whitelabel-templates/Ganamos/GanamosCharger.vue";
import TemplateNavMenu from "@/views/components/whitelabel-templates/common/TemplateNavMenu.vue";
import GNPageEdit from "@/views/components/whitelabel-templates/Ganamos/config/PageEdit/PageEdit.vue";
import {TEMPLATE_DATA} from "@/views/components/whitelabel-templates/Ganamos/const/templateData";
import GMGeneralEdit from "@/views/components/whitelabel-templates/Ganamos/config/GeneralEdit/GeneralEdit.vue";
import LoaderTemplate from "@/views/components/whitelabel-templates/common/loaderTemplate.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {translatableText} from "@core/utils/utils";
import {axiosErrorHandle} from "@core/utils/errorHandler";

const MODULE_STORE_NAME = "app-whitelabel-templates";

export default {

  name: "index",
  components: {
    GMGeneralEdit,
    GNPageEdit,
    TemplateNavMenu,
    GanamosCharger,
    TemplateSizeSelector,
    BPopover,
    BListGroupItem,
    BListGroup,
    BButton,
    BCol,
    BRow,
    BCard,
    LoaderTemplate
  },

  beforeCreate() {
    // Register module
    if (!store.hasModule(MODULE_STORE_NAME))
      store.registerModule(MODULE_STORE_NAME, whitelabelTemplatesModule);
  },

  data() {
    return {
      size: "monitor",
      errorFetchingTemplate: false,
      menu: [
        {icon: "EditIcon", window: "g-edit", name: "General edit"},
        {icon: "ColumnsIcon", window: "p-edit", name: "Layout edit"},
      ],

      selectedMenuWindow: "",
      hoverEditComponent: false,
      closed: false,
      template: {
        templateData: TEMPLATE_DATA
      }
    }
  },


  methods: {
    onToggleSidebar(closed) {
      this.closed = closed;
    },

    handleMenuWindow(menu) {
      this.selectedMenuWindow = this.selectedMenuWindow === menu ? "" : menu;
    },

    async fetchTemplateData() {
      const id = this.$route.query.id;
      const params = {
        whitelabelId: this.getWhitelabelSelect._id,
      };
      this.errorFetchingTemplate = false;
      try {
        let {data: template} = await this.$store.dispatch(
            `${MODULE_STORE_NAME}/fetchTemplate`,
            {id, params}
        );

        this.$store.commit(
            `${MODULE_STORE_NAME}/SET_EDIT_TEMPLATE`,
            template
        );
        this.$store.commit(
            `${MODULE_STORE_NAME}/SET_EDIT_TEMPLATE_RECOVERY`,
            JSON.parse(JSON.stringify(template))
        );
      } catch (e) {
        this.errorFetchingTemplate = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: await translatableText({text: axiosErrorHandle(e).toString()}),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        // TODO DELETE FINALLY
        // this.$store.commit(
        //     "app-whitelabel-templates/SET_EDIT_TEMPLATE",
        //     this.template
        // );
        // this.$store.commit(
        //     "app-whitelabel-templates/SET_EDIT_TEMPLATE_RECOVERY",
        //     JSON.parse(JSON.stringify(this.template))
        // );
        this.errorFetchingTemplate = false;
      }
    },
  },

  mounted() {
    this.fetchTemplateData();
  },

  computed: {
    ...mapState(MODULE_STORE_NAME, [
      "loadingFetchTemplate",
      "editTemplate",
      "reRenderTemplate",
    ]),

    getWhitelabelSelect() {
      return store.state.whitelabelCurrencyNabvar.whitelabel;
    },
  },
}
</script>

<template>
  <div class="template-container">
    <!--     Loading status-->
    <!-- <div v-if="loadingFetchTemplate" class="loading-state">Pedro...</div> -->
     <LoaderTemplate v-if="loadingFetchTemplate"/>

    <!--     Error status-->
    <div v-else-if="errorFetchingTemplate" class="error-state">
      Error, Reintentar...
    </div>

    <div v-else-if="editTemplate" class="main-content d-flex flex-column">
      <!-- Size selector -->
      <template-size-selector
          :sizes="['smartphone', 'tablet', 'square', 'monitor']"
          @selectSize="(size) => (this.size = size)"
      />

      <section class="wrapper">
        <!-- Application loading component -->
        <ganamos-charger
            :hover-edit-component="hoverEditComponent"
            :re-render="reRenderTemplate"
            :size="size"
        />

        Navigation Container
        <TemplateNavMenu
            :menu="menu"
            :selected-menu-window="selectedMenuWindow"
            :closed="closed"
            @menu-window-selected="handleMenuWindow"
            @toggle-sidebar="onToggleSidebar"
        />

        Editing components
        <!--        <g-m-general-edit v-show="selectedMenuWindow === 'g-edit'"/>-->
        <g-n-page-edit v-show="selectedMenuWindow === 'p-edit'"/>
         <!-- Editing components -->
         <g-m-general-edit v-show="selectedMenuWindow === 'g-edit'"/>        
      </section>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.main-content {
  height: 80vh;
  gap: 5px;
}

.size-selector {
  padding: 10px;
}
</style>