var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{attrs:{"content-class":"mt-2","justified":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.header)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-tab',{attrs:{"title":_vm.$t('content'),"active":""}},[_c('b-list-group-item',{staticClass:"px-0",staticStyle:{"background":"none"}},[_c('b-row',[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"name","label-for":"h-name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.name")))]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"h-name","type":"text","state":_vm.getValidationState(validationContext)},on:{"input":function($event){return _vm.updatePreview()}},model:{value:(_vm.header.name),callback:function ($$v) {_vm.$set(_vm.header, "name", $$v)},expression:"header.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)],1)]}}],null,true)})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"BackgroundColor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Background color","label-for":"h-backgroundColor"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.backgroundcolor")))]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"h-backgroundColor","type":"color","value":_vm.header.background_color,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                            key: 'background_color',
                            value: newValue,
                          }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"LightColor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Light Color","label-for":"h-lightColor"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.lightColor")))]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"h-backgroundColor","type":"color","value":_vm.header.light_color,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                            key: 'light_color',
                            value: newValue,
                          }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)],1)],1),_c('b-list-group-item',{staticClass:"px-0",staticStyle:{"background":"none"}},[_c('h6',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-3",modifiers:{"accordion-3":true}}]},[_vm._v(_vm._s(_vm.$t("usermenu")))]),_c('b-collapse',{attrs:{"id":"accordion-3","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"px-1 mt-1"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"ColorMenu"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"ColorMenu","label-for":"ColorMenu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("color")))]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"ColorMenu","type":"color","value":_vm.header.auth.color,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                                  key: 'auth.color',
                                  value: newValue,
                                }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"px-1 mt-1"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"BackgroundMenu"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"BackgroundMenu","label-for":"BackgroundMenu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.backgroundcolor"))+" ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"BackgroundMenu","type":"color","value":_vm.header.auth.background_color,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                                  key: 'auth.background_color',
                                  value: newValue,
                                }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"px-1 mt-1"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"BackgroundMenu"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"BackgroundMenu","label-for":"BackgroundMenu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.backgroundcolor"))+" 1 ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"BackgroundMenu","type":"color","value":_vm.header.auth.background_color_1,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                                  key: 'auth.background_color_1',
                                  value: newValue,
                                }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"px-1 mt-1"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"BackgroundMenu"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"BackgroundMenu","label-for":"BackgroundMenu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("labels.backgroundcolor"))+" 2 ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"BackgroundMenu","type":"color","value":_vm.header.auth.background_color_2,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                                  key: 'auth.background_color_2',
                                  value: newValue,
                                }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"px-1 mt-1"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"BackgroundMenu"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"BackgroundMenu","label-for":"BackgroundMenu"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t("text_color")))]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"BackgroundMenu","type":"color","value":_vm.header.auth.text_color,"state":_vm.getValidationState(validationContext)},on:{"input":function (newValue) { return _vm.onColorChange({
                                  key: 'auth.text_color',
                                  value: newValue,
                                }); }}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)])],1),_c('b-list-group-item',{staticClass:"px-0",staticStyle:{"background":"none"}},[_c('h6',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}]},[_vm._v("Logo")]),_c('b-collapse',{attrs:{"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[_c('div',{staticClass:"px-1 mt-1"},[_c('image-field-form',{staticClass:"w-100",attrs:{"single-button":true,"text":"Logo","value":_vm.header.logo.href,"path":"headers","preview":"true"},on:{"uploadedImage":function($event){return _vm.onUploadedImage($event, 'logo.href')},"error":_vm.errorInLoadImage}}),(_vm.header.logo.href.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('img',{staticClass:"mt-1 rounded",staticStyle:{"max-width":"100%"},attrs:{"src":_vm.header.logo.href,"alt":"Uploaded Image"}}),_c('b-button',{staticClass:"close-btn",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.onClearImage()}}},[_vm._v(" X ")])],1)]):_vm._e()],1)])],1)],1),_c('div',{staticClass:"mt-4 d-flex flex-column",staticStyle:{"gap":"5px"}},[_c('b-button',{staticClass:"w-100",attrs:{"disabled":_vm.loading,"size":"md","type":"submit","variant":"success"}},[_vm._v(" "+_vm._s(_vm.isEditMode ? _vm.$t("buttons.update_and_apply") : _vm.$t("buttons.create_and_apply"))+" ")]),_c('b-button',{on:{"click":function($event){return _vm.resetData()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.cancel")))])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }